import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "gatsby"
/*
 ** See:  https://github.com/rexxars/react-markdown for documentation...
 */

export default function ArticlePost ( {
  title,
  slug,
  postBody,
  published,
  bannerImage
} ) {
  return (
    <>
      <article className="MarkdownPost mx-auto rounded d-md-none">
        <h2 className="mb-0-">
          {/* { slug ? ( <Link to={ slug }>{ title }</Link> ) : title } */}
          {title}
        </h2>
        { published != `` ? <p className="h6 text-muted mb-3">{ published }</p> : `` }
        <hr />
        { bannerImage != `` ? <img src={ bannerImage } /> : `` }
        <ReactMarkdown source={ postBody } escapeHtml={ false } />
      </article>
      <article className="MarkdownPost mx-auto px-md-5 py-md-2 rounded d-none d-md-block shadow">
        <h2 className="mb-0-">
          {/* { slug ? ( <Link to={ slug }>{ title }</Link> ) : title } */}
          {title}
        </h2>
        { published != `` ? <p className="h6 text-muted mb-3">{ published }</p> : `` }
        <hr />
        { bannerImage != `` ? <img src={ bannerImage } /> : `` }
        <ReactMarkdown source={ postBody } escapeHtml={ false } />
      </article>
    </>
  );
}