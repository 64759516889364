import React from "react";
import { graphql } from "gatsby";
import SEO from '../components/seo'
import Layout from '../components/layout'
import Navigation from '../components/Navigation/Navigation'
import Main from '../components/Main/Main'
import Content from '../components/Content/Content'
import Title from '../components/Title/Title'
import Page from '../components/Page/Page'
import ArticlePost from '../components/Article/ArticlePost'
import Footer from '../components/Footer/Footer'

// import Disqus comments
import { DiscussionEmbed } from "disqus-react"

export default function blogPost ( props ) {
  const {
    title,
    slug,
    bannerImage,
    postBody,
    published
  } = props.data.contentfulBlogPost;
  // console.log(props)
  // console.log( props.path )
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: slug, title },
  }
  return (
    <>
      <SEO title={title} />
      <Layout>
        <Navigation />
        <Main>
          <Content>
            <Title title="blog" />
            <Page>
              <div className="Advertisement d-none d-md-block flex-grow-1" />
              <div className="flex-column">
                <ArticlePost
                  title={ title ? title : `` }
                  slug={ props.path }
                  bannerImage={ bannerImage ? bannerImage.fluid.src : `` }
                  postBody={ postBody ? postBody.childMarkdownRemark.html : `` }
                  published={ published ? published : `` }
                />
                <div className="Disqus">
                  <DiscussionEmbed { ...disqusConfig } />
                </div>
              </div>
              <div className="Advertisement d-none d-md-block flex-grow-1" />
            </Page>
          </Content>
          <Footer />
        </Main>
      </Layout>
    </>
  );
}

// blogPost.PropTypes = {
//     data: PropTypes.object.isRequired
// }
const image = `bannerImage {
                fluid(maxWidth: 800) {
                    aspectRatio
                    sizes
                    src
                    srcSet
                }
            }`

export const pageQuery = graphql`
    query blogPostQuery ($slug: String) {
        contentfulBlogPost(slug: {eq: $slug}) {
            title
            slug
            bannerImage {
                fluid(maxWidth: 800) {
                    aspectRatio
                    sizes
                    src
                    srcSet
                }
            }
            postBody {
                childMarkdownRemark {
                    html
                }
            }
            published(formatString: "MMMM DD, YYYY")
        }
    }
`